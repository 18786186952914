
<template>
    <div>
        <div class="title">Background Image</div>
        <div class="bg-grid">
            <button @click="setBackground(image)" v-for="(image, index) in images" :key="index" :style="bgImageHandler(image)">
                <!-- <img :src="`${prefix}${image}`" alt=""> -->
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'prefix' ],
    data() {
        return {
            images: [
                'djr4ibim1pj11.jpg',
                '380883.jpg',
                '380889.jpg',
                '7735cf2731fdd568582c1da933d36a2a-700.jpg',
                // '8eb8806212d25a58c206ae3307b7ba61.jpg',
                'cliffs_waterfalls_mist_nature_68876_2560x1080.jpg',
                'forest_background_by_sendrawz_da57kln-fullview.jpg',
                'house_fairy_tale_art_light_night_101615_3840x2160.jpg',
                'jungle_fantasy_deer_butterflies_night_trees_102121_1920x1200.jpg',
                'M8lJOY.jpg',
                'magic_ball_library_columns_castle_63093_3840x2400.jpg',
                'margarita-dukhovnaya-portfolio4.jpg',
                'mountains_clouds_sea_ship_sailboat_destroyed_69213_1920x1200.jpg',
                'Ori_and_the_Blind_Forest_video_games_nature_trees_forest-1850315.jpg',
                'rnity-Fantasy-Mmo-Rpg-Fantasy-Platform-1deternity-.jpg',
                'stones_wood_dark_light_castle_69204_1920x1200.jpg',
                'trees_green_entrance_stairs_planet_61651_1920x1200.jpg',
                'wp4146168.jpg'
            ]
        }
    },
    methods: {
        setBackground(image){
            console.log("SHOULD CHANGE BACKGROUND " + image)
            this.$emit('setBackground', image)
        },

        bgImageHandler(image){
            return {
            "background-image": `url(${this.prefix}/backgrounds/${image})`
            }
        }
    },
}
</script>

<style scoped>
    button {
        width: 150px;
        height: 150px;
        background-size: cover;
        cursor: pointer;
    }

    button > img {
        width: 200px;
        height: auto;
        
    }

    .title {
        color: white;
        margin: 16px;
    }

    .bg-grid{
        display: flex;
        flex-wrap: wrap;
        align-content:flex-start;
        align-items: flex-start;

    }
</style>
