<template>
  <li> [{{formattedDate}}] {{message.user.name}} says: {{message.content}} </li>
</template>

<script>
export default {
    props: ['message'],
    computed: {
      formattedDate() {
        const date = new Date(this.message.timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      }
    }
}
</script>

<style>

</style>