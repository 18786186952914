
<template>
<div>
    <div class="title">Artifacts</div>
    <div class="artifact-grid">
        <button @click="setBackground(image)" v-for="(image, index) in images" :key="index">
            <img :src="`${prefix}/artifacts/${image}`" alt="">
        </button>
    </div>
</div>
</template>

<script>
export default {
    props: [ 'prefix' ],
    data() {
        return {
            images: [
                // 'Albion_2.jpg',
                'image0.jpg',
                'unknown (1).png',
                // 'unknown (10).png',
                'unknown (11).png',
                // 'unknown (12).png',
                'unknown (13).png',
                'unknown (14).png',
                'unknown (15).png',
                // 'unknown (16).png',
                // 'unknown (2).png',
                // 'unknown (3).png',
                // 'unknown (4).png',
                // 'unknown (5).png',
                // 'unknown (6).png',
                // 'unknown (7).png',
                // 'unknown (8).png',
                // 'unknown (9).png',
                // 'unknown.png',
                // 'wp4146168.jpg'
            ]
        }
    },
    methods: {
        setBackground(image){
            console.log("SHOULD CHANGE ARTIFACT " + image)
            this.$emit('setArtifact', image)
        }
    }
}
</script>

<style scoped>
    button {
        cursor: pointer;
        margin: 4px;
        /* padding:0; */
        /* background-color: rgba(0, 0, 0, 0); */
        /* border: none; */
    }

    button > img {
        height: 120px;
        width: auto;
        
    }

    .title{
        color: white;
        margin: 16px;
    }

    .artifact-grid {
        display: flex;
        flex-wrap: wrap;
        align-content:flex-start;
        align-items: flex-start;
    }
</style>
