<template>
  <div class="hello">
    <transition name="slide-fade">
        <Artifact class="artifact" v-show="artifactToggle" :prefix="prefix" :image="artifactImage"/>
    </transition>

    <div class="columns">
      <div class="flexlife">
        <div class="dm-panel" v-if="isDM">
            <BackgroundSwitcher :prefix="prefix" @setBackground="setBackground" />
            <ArtifactToggle :prefix="prefix" @setArtifact="setArtifact"/>
            <MusicSwitcher @setAudio="setAudio"/>
        </div>

        <audio ref="player" controls>
          <source :src="`${this.prefix}/music/${bgMusic}`">
          Your browser does not support the audio element.
        </audio>

        <div>
          <ul id="messages" ref="messages">
            <template v-for="(message, i) in messages">
              <message ref="message" :message="message" :key="i" />
            </template>
          </ul>

          <chat-input @send="send" />
        </div>
      </div>
      <video-chat v-if="socket" :socket="socket"/>
    </div>
  </div>
</template>

<script>
import { io } from "socket.io-client"
import ChatInput from "./ChatInput"
import Message from "./Message"
import Artifact from "./Artifact"
import VideoChat from "./VideoChat"

import BackgroundSwitcher from './dmtools/BackgroundSwitcher'
import MusicSwitcher from './dmtools/MusicSwitcher'
import ArtifactToggle from './dmtools/ArtifactToggle'

export default {
  name: 'Chat',
  props: [ 'prefix' ],
  components: {
    ChatInput,
    Message,
    Artifact,
    BackgroundSwitcher,
    MusicSwitcher,
    ArtifactToggle,
    VideoChat
  },
  data() {
    return {
      socket: null,
      messages: [],
      bgMusic: null,
      artifactImage: null,
      artifactToggle: false,
      timeout: 5000  

    }
  },
  computed: {
    isDM(){
      return window.location.search == '?dm=true'
    }
  },
  watch: {
    bgMusic: function() {
      this.$refs.player.load()
      this.$refs.player.play()
      this.$refs.player.loop = true;
    },
    artifactImage: function() {
      console.log("artifact image changed ")
    }    
  },  
  methods: {
    send(input){
        if (input.length > 0) {
            this.socket.emit('chat message', input);
        }
    },
    setBackground(image){
      console.log("------emit change image")
      this.socket.emit('change image', image);
    },
    setArtifact(image){
      console.log("------emit change artifact")
      this.socket.emit('change artifact', image);
    },    
    setAudio(song){
      console.log("--!!----emit change song")
      this.socket.emit('change audio', song);
    },    
    test(image){
      console.log("TEST:  " + image)
      this.$emit('changeImage', image)
    },
    changeAudio(song){
      console.log("##Told to change audio to " + song)
      this.bgMusic = song
    },
    showArtifact(image){
      this.artifactImage = image
      this.artifactToggle = true
      setTimeout(() => {
        this.artifactToggle = false
      }, this.timeout)
    }
  },
  mounted() {

    this.$refs.player.volume = 0.5
    
    console.log(window.location)
    this.socket = io(this.prefix);    

    this.socket.on('chat message', (msg) => {
        this.messages.push(msg)
        // window.scrollTo(0, document.body.scrollHeight);
        setTimeout(() => {
          this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
        }, 200)
        
    });

    this.socket.on('change image', (image) => {
        console.log("Client: told to change image to " + image)
        this.test(image)
    });

    this.socket.on('change artifact', (image) => {
        console.log("Client: told to change artifact to " + image)
        this.showArtifact(image)
    });

    this.socket.on('change audio', (song) => {
        console.log("Client: told to change audio to " + song)
        this.changeAudio(song)
    });    
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
audio{
  margin: 5px;
}

.columns {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.flexlife {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hello{
  height: 100%;
  transition: background-image 2s;
}

.dm-panel{
  display: flex;
  justify-content: space-between;
}

#messages { 
  height: 160px;
  overflow-y: scroll;
  list-style-type: none; 
  margin: 0; 
  padding: 0;
}
#messages > li { 
  color: white;
  padding: 0.5rem 1rem; 
  background: #2c2c2c5e; 
}
#messages > li:nth-child(odd) { 
  background: #2c2c2c8c; 
}


.artifact {
  position: absolute;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(500px);
  opacity: 1;
}
</style>
