<template>
  <div id="app" :style="bgImageHandler">
    <Chat :prefix="prefix" @changeImage="changeImage"/>
  </div>
</template>

<script>
import Chat from './components/Chat.vue'


export default {
  name: 'App',
  components: {
    Chat
  },
  data(){
    return {
      prefix: process.env.NODE_ENV == "development" ? "http://localhost:3333": "",
      bgImage: 'M8lJOY.jpg',
    }
  },
  computed: {
    bgImageHandler(){
      return {
        "background-image": `url(${this.prefix}/backgrounds/${this.bgImage})`
      }
    }
  },
  methods: {
    changeImage(image){
      console.log("##Told to change image to " + image)
      this.bgImage = image
    }   
  },
  mounted(){
    console.dir()
  }  
}
</script>

<style scoped>
  #app{
    transition: background-image 2s;
    background-size: cover;
    height: 100vh;
  }
</style>
