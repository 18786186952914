<template>
    <div class="video-chat">
        <video class="my-video" ref="myFilteredVideo" autoplay="autoplay" playsinline :srcObject.prop="myFilteredVideoStream"></video>
        <div>
            <button v-for="(shader, index) in shaders" @click="setShader(shader)" :key="index">{{index}}</button>
        </div>

        <div id="video-grid" v-if="videoStreams.length">
            <div v-for="(videoStream, key) in videoStreams" :key="key"  class="theirvideo" >
                <!-- {{videoStream.id}} -->
                <video playsinline :srcObject.prop="videoStream" autoplay="autoplay"></video>
            </div>

        </div>

        <div>
            <video style="visibility: hidden" playsinline ref="myVideo" class="my-video" :srcObject.prop="myVideoStream" autoplay="autoplay"></video>
            <canvas style="display:none" class="my-video-buffer" ref="myVideoBuffer"></canvas>
            <canvas style="display:none" class="my-video-gl-buffer" ref="myVideoGLBuffer"></canvas>
            <canvas style="display:none" class="my-video-output-buffer" ref="myVideoOutputBuffer"></canvas> 
        </div>
    </div>
    
</template>

<script>
import Peer from 'peerjs';
import GlslCanvas from 'glslCanvas'

const ROOM_ID = '12345' //this should be coming from exiting random room generator

export default {
    props: ['socket'],
    data(){
        return {
            peer: null,
            peers: {},
            videoStreams: [],
            myVideoStream: null,
            myFilteredVideoStream: null,
            myVideoBufferRenderLoop: null,
            myGlslObject: null,
            shaders: [
                {
                    name: "Default",
                    shader: "gl_FragColor = vec4(color, 1.0);"
                },
                {
                    name: "Purple",
                    shader: "gl_FragColor = vec4(color * vec3(0.9, 0.0, 1.0), 1.0);"
                },
                {
                    name: "Yellow",
                    shader: "gl_FragColor = vec4(color * vec3(1.5, 1.5, 0.0), 1.0);"
                },
                {
                    name: "High Contrast",
                    shader: "color = smoothstep(vec3(0.2), vec3(0.8), color); gl_FragColor = vec4(color, 1.0);"
                },
                {
                    name: "Invert",
                    shader: "gl_FragColor = vec4(vec3(1.0) - color, 1.0);"
                },
                {
                    name: "Rave",
                    shader: "color += vec3(sin(0.5 * u_time), sin(0.1 * u_time + 1.0), sin(0.7 * u_time)); gl_FragColor = vec4(color, 1.0);"
                },                                                                                
            ],
            currentShader: undefined,
        }
    },
    methods: {
        updateCanvas(){
            console.log("Update Canvas")
            const vertexShader = `
                #ifdef GL_ES
                precision mediump float;
                #endif
                uniform sampler2D u_texture;
                uniform vec2 u_resolution;
                uniform float u_time;
                void main() {
                vec2 st = gl_FragCoord.xy / u_resolution.xy;
                float x = gl_FragCoord.x;
                float y = gl_FragCoord.y;
                vec3 color = texture2D(u_texture, st).rgb;
                ${this.currentShader.shader}
                }
            `;

            if (this.myGlslObject) {
                console.log("Loading Shader")
                this.myGlslObject.load(vertexShader);
                console.dir(this.myGlslObject)
            }
        },
        renderCanvas(){
            const video = this.$refs.myVideo
            const buffer = this.$refs.myVideoBuffer
            const canvas = this.$refs.myVideoGLBuffer

            buffer.width = video.videoWidth;
            buffer.height = video.videoHeight;
            
            buffer.getContext('2d').drawImage(video, 0, 0);

            canvas.width = buffer.width;
            canvas.height = buffer.height;

            this.$refs.myVideoOutputBuffer.width = video.videoWidth;
            this.$refs.myVideoOutputBuffer.height = video.videoHeight;

            if (!this.myGlslObject) {
                console.log("No GLSL Canvas")
                this.myGlslObject = new GlslCanvas(canvas);
                this.updateCanvas();
            }            

            var dataURL = buffer.toDataURL();
            this.myGlslObject.setUniform('u_texture', dataURL);
            

            //render to output canvas
            const outputContext = this.$refs.myVideoOutputBuffer.getContext('2d')
            // console.log(outputContext)
            // if (this.$refs.myVideoGLBuffer){
                try {
                    outputContext.drawImage(this.$refs.myVideoGLBuffer, 0, 0)
                } catch(e){
                    console.log("output context not ready or the video gl buffer")
                }
            // }
            

            this.myVideoBufferRenderLoop = window.requestAnimationFrame(this.renderCanvas);
        },

        connectToNewUser(userId, stream) {

            console.log("CONNECT TO NEW USER")

            const call = this.peer.call(userId, stream)
            this.peers[userId] = call

            call.on('stream', userVideoStream => {
                console.log("----------   incoming stream")

                this.peers[call.peer].videoStreamId = userVideoStream.id

                //useful?
                if (!this.videoStreams.find(videoStream => videoStream.id == userVideoStream.id)){
                    console.dir(userVideoStream)
                    this.videoStreams.push(userVideoStream)
                }
                
            })

            call.on('close', () => {
                
                // video.remove()
                //unsure what to do here now
                console.log("SHOULD remove old user but does nothing")
                console.dir(call.peer)
            })
            
        },

        setShader(shader){
            console.log("setting shader to " + shader.name)
            this.currentShader = shader
            this.updateCanvas()
        }
    },
    mounted(){
        this.setShader(this.shaders[5])

        this.$refs.myVideo.muted = true
        this.$refs.myFilteredVideo.muted = true

        this.peer = new Peer(undefined, {
            host: '/',
            port: process.env.NODE_ENV == "development" ? 3333: 443,
            path: '/myapp',
            config: { 
                'iceServers': [
                    { 'url': 'stun:stun.l.google.com:19302' }
                ] 
            }            
        })

        this.socket.on('user-disconnected', userId => {
            if (this.peers[userId]) {

                this.videoStreams = this.videoStreams.filter(videoStream => {
                    return videoStream.id !== this.peers[userId].videoStreamId
                })                

                this.peers[userId].close()
                delete this.peers[userId]
            }
        })

        this.peer.on('open', id => {
            
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            }).then(myStream => {

                console.log("__Webcam Ready")
                
                this.myVideoStream = myStream

                //filters stuff
                this.renderCanvas()
                this.myFilteredVideoStream = this.$refs.myVideoOutputBuffer.captureStream()
                this.myVideoStream.getAudioTracks().map((audioTrack) => {
                    this.myFilteredVideoStream.addTrack(audioTrack)
                })
                //end filters stuff
            
                this.peer.on('call', call => {

                    console.log("INCOMING CALL")
                    console.dir
                    call.answer(this.myFilteredVideoStream)
                                   
                    call.on('stream', userVideoStream => {
                        this.peers[call.peer] = call
                        this.peers[call.peer].videoStreamId = userVideoStream.id
                        if (!this.videoStreams.find(videoStream => videoStream.id == userVideoStream.id)){
                            this.videoStreams.push(userVideoStream)
                        }
                    })
                })
            
                this.socket.on('user-connected-webrtc', userId => {
                    console.log("***** on user connected")
                    this.connectToNewUser(userId, this.myFilteredVideoStream)
                })

                console.log("Joining Room")
                this.socket.emit('join-room', ROOM_ID, id)
            }).catch(function(err) {
                console.log("did not get user media")
                console.error(err)

            });  
        })
    }
}
</script>

<style scoped>
    video{
        border-radius: 15px;
    }

    .video-chat {
        /* display: flex; */
    }

    .my-video {
        padding: 10px;
        /* border-radius: 5px; */
        /* background-color: cyan; */
        width: 200px; 
    }

    .theirvideo {
        /* background-color: purple; */
        padding: 20px;
    }

    #video-grid{
        display: grid;
        grid-template-columns: 200px;
        grid-auto-rows: repeat(auto-fill, 200px);
        background: 1px solid red;
    }

    #video-grid > div >  video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

</style>
