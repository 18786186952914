<template>
    <form id="form" action="">
        <input v-model="message" id="input" autocomplete="off" @keydown.enter="send" /><button @click.prevent="send">Send</button>
    </form>
</template>

<script>
export default {
    data() {
        return {
            message: ''
        }
    },
    methods: {
        send() {
            this.$emit('send', this.message)
            this.message = ''
        }
    }
}
</script>

<style>
#form { 
    background: rgba(0, 0, 0, 0.15); 
    padding: 0.25rem; 
    /* position: fixed; 
    bottom: 0; 
    left: 0; 
    right: 0;  */
    display: flex; 
    height: 3rem; 
    box-sizing: border-box; 
    backdrop-filter: blur(10px); 
    }
#input { 
    border: none; 
    padding: 0 1rem; 
    flex-grow: 1;
    border-radius: 2rem; 
    margin: 0.25rem; 
    }
#input:focus { 
    outline: none; 
    }
#form > button { 
    background: #333; 
    border: none; 
    padding: 0 1rem;
    margin: 0.25rem; 
    border-radius: 3px; 
    outline: none; 
    color: #fff; }
</style>