
<template>
<div class="music-grid">
    <div class="title">Background Music</div>
    <button @click="setSong(song)" v-for="(song, index) in songs" :key="index">
        {{song}}
    </button>
</div>
</template>

<script>
export default {
    data() {
        return {
            songs: [
                'Calm Atmosphere 01.mp3',
                'Calm Atmosphere 02.mp3',
                'cursed_krypta_minimum_loop.mp3',
                'desert_solo_loop.mp3',
                'encounter_loop.mp3',
                'Exploring Magic 1.mp3',
                'Exploring Magic 2.mp3',
                'Exploring Scary 1.mp3',
                'Exploring Scary 2.mp3',
                'Forests and Villages 01.mp3',
                'Forests and Villages 02.mp3',
                'frozen_lands_short_loop.mp3',
                'grassland_short_loop.mp3',
                'hurry_percussion_only_loop.mp3',
                'lower_cavern_loop.mp3',
                'Menu_Titles 01.mp3',
                'Menu_Titles 02.mp3',
                'natural_cave_loop.mp3',
                'Sneaking_Night.mp3',
                'tavern_loop.mp3',
                'tension_loop.mp3',
                'Title_Castle.mp3'
            ]
        }
    },
    methods: {
        setSong(song){
            console.log("SHOULD CHANGE SONG")
            this.$emit('setAudio', song)
        }
    }
}
</script>

<style scoped>

    .title {
        color: white;    
        margin: 6px;
    }

    button > img {
        width: 200px;
        height: auto;
        
    }

    button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.918);
        color: rgba(0, 0, 0, 0.801);
        padding: 4px 8px;
        margin: 4px;
        border-radius: 5px;
        font-weight: bold;
        border: none;
    }

    .music-grid{
        display: flex;
        flex-direction: column;
        margin: 10px;
        max-height: 40vh;
    }
</style>
